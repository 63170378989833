import React, {useContext} from "react";

import {A11y} from "@churchofjesuschrist/eden-buttons";
import {
  Profile,
  World,
  GearOutline,
  Info
} from "@churchofjesuschrist/eden-icons";
import {
  white,
  blue25,
  spacing24,
  spacing16,
  spacing8} from "@churchofjesuschrist/eden-style-constants";
import styled from "styled-components";

import useTranslation from "../../../utils/use-translations";
import AdminMenu from "../admin-menu/admin-menu";
import DropDownMenu from "../drop-down-menu/drop-down-menu";
import LanguagesMenu from "../languages-menu/languages-menu";
import signedOnUserContext from "../master-layout/signed-on-user-context";
import ReleaseNotesModal from "../release-notes-modal/release-notes-modal";
import releaseNotesService from "../release-notes-modal/release-notes-service";
import useReleaseNotes from "../release-notes-modal/use-release-notes";
import UserProfileMenu from "../user-profile-menu/user-profile-menu";

const Button = styled(A11y)`
flex:1;
`;

const AdminBtn = styled.div`
margin-right:${spacing16};
`

const MenuDivider = styled.div`
border-inline-start: 1px solid ${blue25};
margin:0 ${spacing8};
align-self:stretch;
`;

const Container = styled.div`
justify-content:flex-end;
display:flex;
`
const InnerFlexContainer = styled.div`
display:flex;
`;

const stringGroups = [
  {
    name: "shared",
    keys: ["whatsNew", "releaseNotes"]
  }
];

const defaultObj = {};
const defaultNoteService = releaseNotesService();

/*Note that our MVP does not include the search
and we probably won't ever add the grid square icon thingy back in
as it came from copy paste and has no real functionality attached to it. */
const TopBarMenus = ({ flagService, noteService = defaultNoteService }) => {
  const { managedStrings } = useTranslation(stringGroups);
  const {
    releaseNotes,
    releaseNotesOpen,
    releaseNotesStatus,
    closeReleaseNotes,
    openReleaseNotes,
  } = useReleaseNotes({ service: noteService });

  const {
    user = defaultObj,
    impersonatingUser,
  } = useContext(signedOnUserContext) ?? defaultObj;
  let canAdmin = user.canAdmin;
  if (impersonatingUser) {
    canAdmin = impersonatingUser.canAdmin;
  }

  return (<><Container data-id="top-bar-tools">
    <InnerFlexContainer>
      {canAdmin && (<AdminBtn><DropDownMenu Icon={GearOutline}>
        <AdminMenu flagService={flagService} />
      </DropDownMenu></AdminBtn>)}

      {(releaseNotes || releaseNotes?.length) && <>
        <Button data-id="release-notes"
          onClick={openReleaseNotes}>
          <Info color={white} size={spacing24} title={managedStrings.whatsNew} />
        </Button>
        <MenuDivider />
      </>}
      <DropDownMenu Icon={World} mobileWidth="325px" desktopWidth="500px">
        <LanguagesMenu />
      </DropDownMenu>
      <MenuDivider />
      <DropDownMenu Icon={Profile}>
        <UserProfileMenu />
      </DropDownMenu>
      {releaseNotes?.length && (<ReleaseNotesModal
        isOpen={releaseNotesOpen}
        onClose={closeReleaseNotes}
        releaseNotes={releaseNotes}
        releaseNotesStatus={releaseNotesStatus}
      />)}
    </InnerFlexContainer>
  </Container>

  </>);
}

export default TopBarMenus;